<template>
    <div class="row align-items-center">
        <div class="col-3">
            <strong v-if="pickup.pickup_date.isToday">
                Vandaag
            </strong>
            <strong v-else-if="pickup.pickup_date.isTomorrow">
                Morgen
            </strong>
            <strong v-else>
                {{ pickup.pickup_date.formatted[0].toUpperCase() + pickup.pickup_date.formatted.slice(1) }}
            </strong>
        </div>
        <div class="col-2">
            #{{ pickup.order.order_nr }}
        </div>
        <div class="col-2">
            {{ pickup.order.name }}
        </div>
        <div class="col-5 d-flex justify-content-end flex-wrap gap-2">
            <a :href="route('orders.edit', pickup.order.id)" target="_blank" class="btn btn-primary">
                <i class="far fa-eye"></i> Order bekijken
            </a>
            <button
                v-if="pickup.order.schedule_id"
                type="button"
                class="btn btn-secondary"
                disabled
            >
                <!-- Order permanent in planning -->
                <i class="far fa-check"></i> Toegevoegd
            </button>
            <span
                v-else-if="loading"
                class="btn btn-warning"
                >
                <i class="far fa-circle-notch fa-spin"></i>
            </span>
            <button
                v-else
                type="button"
                @click="this.confirmPickup(pickup.id)"
                class="btn btn-warning"
            >
                <!-- Order not in planning -->
                Toevoegen <i class="far fa-arrow-right"></i>
            </button>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        pickup: Object,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        confirmPickup(pickupId) {
            this.loading = true;
            axios({
                method: 'post',
                url: route('pickups.confirm', pickupId),
            }).then((response) => {
                this.pickup.order.schedule_id = response.data;
                this.loading = false
            }).catch((response) => {
                console.warn(response);
                alert('Er is iets fout gegaan bij het toevoegen aan de planning. Ververs de pagina om het opnieuw te proberen.');
            });
        }
    }
}
</script>
