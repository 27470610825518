"use strict";

const set_configurators = document.querySelectorAll('form.product-configurator');
if (typeof set_configurators !== 'undefined' && set_configurators !== null) {
    for (let i = 0; i < set_configurators.length; i++) {
        set_configurators[i].addEventListener('submit', function (e) {

            const form = this;
            const organisation = document.querySelector('[name="organisation_id"]')?.value;

            // Asynchronously submit the form using the method and the action defined on the form element
            const formdata = new FormData(form);
            formdata.set('organisation', organisation);

            fetch(form.action, {
                method: form.method,
                body: formdata,
            })
                .then(response => response.json())
                .then(function (response) {
                    /**
                     * @type {Object} response
                     * @type {string} response.status
                     * @type {?string} response.products - HTML of pending set if status === success
                     * @type {?string} response.errors - Errors if status !== success
                     * @type {?number} response.price - Price of added set
                     */
                    if (response.status !== 'success') {
                        alert(response.errors.join("\n"));
                        return;
                    }
                    const template = document.createElement('template');
                    template.innerHTML = response.products;
                    const parent = document.getElementById('products');
                    // const before = parent.firstChild;
                    if (response.replace) {
                        const replace_element = parent.querySelector(`#${response.replace}`);
                        console.log(replace_element, replace_element.parentElement);
                        replace_element.parentElement.insertBefore(template.content.childNodes[0], replace_element);
                        replace_element.style.display = '';
                        const fnstring = 'remove' + response.replace_type;
                        console.log(response, fnstring, window[fnstring]);
                        window[fnstring].apply(null, [response.replace_id]);
                    } else {
                        const before = null;
                        for (let j = 0; j < template.content.childNodes.length; j++) {
                            parent.insertBefore(template.content.childNodes[j], before);
                        }
                    }

                    // Update the total price
                    updateOrderTotal(response.price);
                    updateProductTotal();
                    updateTransportTotal();

                    closeConfigurator(form);
                });

            // Don't synchronously submit the form
            e.preventDefault();
        });
    }
}

function closeConfigurator(configurator) {
    // Hide the configurator
    const allConfigurators = document.getElementById('addProductConfigurator');
    allConfigurators.style.display = 'none';

    let currentConfigurator;
    if (typeof configurator === 'string' || configurator instanceof String) {
        currentConfigurator = document.getElementById(configurator);
    } else {
        currentConfigurator = configurator;
    }
    currentConfigurator.style.display = 'none';

    // Clear the forms
    currentConfigurator.reset();

    const reset = currentConfigurator.querySelector('input[name=replace]');
    if (isset(reset)) {
        reset.value = '';
    }
}

window.closeConfigurator = closeConfigurator;

function getCSRFtoken() {
    return document.querySelector('meta[name=csrf-token]').content;
}

window.getCSRFtoken = getCSRFtoken;

function removeNewSet(id) {
    // Update total
    const element = document.getElementById(`set_${id}`);
    const price = element.dataset.price;
    updateOrderTotal(-1 * parseFloat(price));

    // Remove local element
    element.remove();

    // Update costs
    updateTransportTotal();
    updateProductTotal();
}

window.removeNewSet = removeNewSet;

function removeExistingSet(id) {
    // Update changes element, to reflect changes server side
    const changes = document.getElementById('changes');
    console.log(changes, changes.value);
    let json = JSON.parse(changes.value);
    if (!json.hasOwnProperty('sets_removed')) {
        json.sets_removed = [];
    }
    json.sets_removed.push(id);
    changes.value = JSON.stringify(json);

    // Update total
    const element = document.getElementById(`set_${id}`);
    const price = element.dataset.price;
    updateOrderTotal(-1 * parseFloat(price));

    // Remove local element
    element.remove();

    // Update costs
    updateTransportTotal();
    updateProductTotal();
}

window.removeExistingSet = removeExistingSet;

function removeNewProduct(id) {
    // Update total
    const element = document.getElementById(`product_${id}`);
    const price = element.dataset.price;
    updateOrderTotal(-1 * parseFloat(price));

    // Remove local element
    element.remove();

    // Update costs
    updateTransportTotal();
    updateProductTotal();
}

window.removeNewProduct = removeNewProduct;

function removeExistingProduct(id) {
    // Update changes element, to reflect changes server side
    const changes = document.getElementById('changes');
    console.log(changes, changes.value);
    let json = JSON.parse(changes.value);
    if (!json.hasOwnProperty('products_removed')) {
        json.products_removed = [];
    }
    json.products_removed.push(id);
    changes.value = JSON.stringify(json);

    // Update total
    const element = document.getElementById(`product_${id}`);
    const price = element.dataset.price;
    updateOrderTotal(-1 * parseFloat(price));

    // Remove local element
    element.remove();

    // Update costs
    updateTransportTotal();
    updateProductTotal();
}

window.removeExistingProduct = removeExistingProduct;


function unlinkProduct(order_product_id, link_title, route) {
    if (!confirm(`Weet je zeker dat je de koppeling met ${link_title} wilt verbreken? Alle producten en sets die hier aan gekoppeld zijn worden dan niet meer gesynchroniseerd. Dit kan niet ongedaan gemaakt worden.`)) {
        return;
    }

    unlink('product', order_product_id, route);
}

window.unlinkProduct = unlinkProduct;

function unlinkSet(order_set_id, link_title, route) {
    if (!confirm(`Weet je zeker dat je de koppeling met ${link_title} wilt verbreken? Alle producten en sets die hier aan gekoppeld zijn worden dan niet meer gesynchroniseerd. Dit kan niet ongedaan gemaakt worden.`)) {
        return;
    }

    unlink('set', order_set_id, route);
}

window.unlinkSet = unlinkSet;

function unlink(type, id, route) {
    // Asynchronously submit the form using the method and the action defined on the form element
    fetch(route, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'_token': getCSRFtoken(), '_method': 'DELETE', 'type': type, 'id': id}),
    })
        .then(response => response.json())
        .then(function (response) {
            if (response.status === 'success') {
                window.location.reload();
            } else {
                alert(`Er is iets fout gegaan. ${response.message}`);
            }
        });
}

function editProduct(order_product, configurator) {
    console.log(order_product, configurator);

    const product_element = document.getElementById(`product_${order_product}`);
    let settingsElement = product_element.querySelector(`input[type=hidden][name="order_product[${order_product}]"]`);
    let isNew = false;
    if (!isset(settingsElement)) {
        isNew = true;
        settingsElement = product_element.querySelector(`input[type=hidden][name="order_product[new_${order_product}]"]`);
    }
    const settings = JSON.parse(settingsElement.value);

    settings.replace = isNew ? `new_${order_product}` : order_product;

    showProductConfigurator(configurator, 'edit', settings);
}

window.editProduct = editProduct;

function editSet(order_set, configurator) {
    console.log(order_set, configurator);

    const set_element = document.getElementById(`set_${order_set}`);
    let settingsElement = set_element.querySelector(`input[type=hidden][name="order_set[${order_set}]"]`);
    let isNew = false;
    if (!isset(settingsElement)) {
        isNew = true;
        settingsElement = set_element.querySelector(`input[type=hidden][name="order_set[new_${order_set}]"]`);
    }
    const settings = JSON.parse(settingsElement.value);

    settings.replace = isNew ? `new_${order_set}` : order_set;

    showProductConfigurator(configurator, 'edit', settings);
}

window.editSet = editSet;

function showProductConfigurator(shown_configurator, type, settings = null) {
    if (typeof shown_configurator === 'string' || shown_configurator instanceof String) {
        shown_configurator = document.getElementById(`configurator_${shown_configurator}`);
    }

    const configurator_group = document.getElementById('addProductConfigurator');
    configurator_group.style.display = '';
    const configurators = [...configurator_group.children];
    configurators.forEach(function (configurator) {
        configurator.style.display = 'none';
    });
    shown_configurator.style.display = '';

    const change_types = shown_configurator.querySelectorAll('[data-configurator-type]');

    change_types.forEach(function (change_type) {
        if (change_type.dataset.configuratorType === type) {
            change_type.style.display = '';
        } else {
            change_type.style.display = 'none';
        }
    });

    if (settings !== null) {
        const replace_element = shown_configurator.querySelector('input[type=hidden][name=replace]');
        replace_element.value = settings.replace;

        const amount_element = shown_configurator.querySelector('input[name=amount]');
        amount_element.value = settings.amount;
        const matrass_element = shown_configurator.querySelector('select[name=mattrass]');
        if (isset(matrass_element)) {
            matrass_element.value = settings.mattrass ?? '';
        }
        const topper_element = shown_configurator.querySelector('select[name=topper]');
        if (isset(topper_element)) {
            topper_element.value = settings.topper ?? '';
        }

        for (let attribute_id in settings.attributes) {
            const option_id = settings.attributes[attribute_id];
            const attribute_element = shown_configurator.querySelector(`select[name="attribute[${attribute_id}]"]`);
            attribute_element.value = option_id ?? '';
        }
    }
}

window.showProductConfigurator = showProductConfigurator;

function updateTotalFactory(type) {
    return function () {
        if (!window.do_price) {
            return;
        }
        const cost_checked = document.querySelectorAll(`input:checked[data-${type}-cost-check]`);
        const cost_elements = document.querySelectorAll(`[data-${type}-cost]`);
        const cost_select_options = document.querySelectorAll(`option:checked[data-${type}-cost-option]`);

        let cost = 0;

        if (cost_checked.length !== 0) {
            cost_checked.forEach(function (cost_check) {
                console.log("Update checked", cost_check, cost);
                let current_cost = (parseFloat(cost_check.dataset[`${type}CostCheck`] ?? 0));
                cost += isNaN(current_cost) ? 0 : current_cost;
            });
        }

        if (cost_elements.length !== 0) {
            cost_elements.forEach(function (cost_element) {
                let current_cost = (parseFloat(cost_element.dataset[`${type}Cost`] ?? 0));
                cost += isNaN(current_cost) ? 0 : current_cost;
            });
        }

        if (cost_select_options.length !== 0) {
            cost_select_options.forEach(function (cost_option) {
                let current_cost = (parseFloat(cost_option.dataset[`${type}CostOption`] ?? 0));
                cost += isNaN(current_cost) ? 0 : current_cost;
            })
        }

        console.log(`Updating ${type} total, new total = ${cost.toString()}`, cost_checked, cost_checked, cost_select_options);

        const string = cost.toString();
        updateElementContent(cost, `data-${type}-total`);
        updateElementValue(string, `data-${type}-total`, function (element) {
            return element.readOnly
        });
        updateElementMoney(cost, `data-${type}-total`);

        return cost;
    }
    // Maybe save all the generated updateTotalFunctions in an array so they can easily be all called
}

function updateElementFactory(type, attribute, transform = (notransform) => notransform) {
    return function (new_content, data_attribute, condition = () => true) {
        const elements = document.querySelectorAll(`[${data_attribute}=${type}]`);
        elements.forEach(function (element) {
            if (condition(element)) {
                element[attribute] = transform(new_content);
            }
        });
    }
}

const updateProductTotal = updateTotalFactory('product');
window.updateProductTotal = updateProductTotal;

const updateTransportTotal = updateTotalFactory('transport');
window.updateTransportTotal = updateTransportTotal;

const updateAssemblyTotal = updateTotalFactory('assembly');
window.updateAssemblyTotal = updateAssemblyTotal;

const updateElementContent = updateElementFactory('textContent', 'textContent');
window.updateElementContent = updateElementContent;

const updateElementMoney = updateElementFactory('money', 'textContent',
    (content) => Number(content).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'}));
window.updateElementMoney = updateElementMoney;

const updateElementValue = updateElementFactory('value', 'value');
window.updateElementValue = updateElementValue;

const readonly_element = document.querySelector('[data-update-readonly]');
if (readonly_element) {
    readonly_element.addEventListener('change', function () {
        const targets = document.querySelectorAll('[data-update-readonly-target]');
        const readonly = this.checked;
        targets.forEach(function (target) {
            if (readonly) {
                target.setAttribute('readonly', "readonly");
            } else {
                target.removeAttribute('readonly');
            }
        });
        updateOrderTotal(0);
        updateTransportTotal();
        updateAssemblyTotal();
        updateProductTotal();
        updatePriceProfit();
    });
}

function updateOrderTotal(change) {
    if (!window.do_price) {
        return;
    }
    // if (typeof order_total === 'undefined' || order_total === null) {
    //     return;
    // }
    // let total = parseFloat(order_total.dataset.total);
    // if (isNaN(total)) {
    //     total = 0;
    // }
    // total += change;
    // // total = Math.floor(total * 100) / 100;
    // order_total.dataset.total = total.toString();
    let total = updateProductTotal() + updateTransportTotal() + updateAssemblyTotal();
    updateElementContent(total.toString(), 'data-order-total');
    updateElementValue(total.toString(), 'data-order-total', function (element) {
        return element.readOnly
    });
    const order_total = document.getElementById('order-total');
    if (typeof order_total !== 'undefined' && order_total !== null) {
        order_total.textContent = total.toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'});
    }
}

const price_customer_element = document.getElementById('price_customer');
const price_production_element = document.getElementById('price_production');
const price_assembly_element = document.getElementById('price_assembly');
const price_transport_element = document.getElementById('price_transport');
const price_profit_element = document.getElementById('price_profit');

window.do_price = false;
if (typeof price_customer_element !== 'undefined' && price_customer_element !== null &&
    typeof price_production_element !== 'undefined' && price_production_element !== null &&
    typeof price_assembly_element !== 'undefined' && price_assembly_element !== null &&
    typeof price_transport_element !== 'undefined' && price_transport_element !== null &&
    typeof price_profit_element !== 'undefined' && price_profit_element !== null) {

    price_customer_element.addEventListener('input', updatePriceProfit);
    price_production_element.addEventListener('input', updatePriceProfit);
    price_assembly_element.addEventListener('input', updatePriceProfit);
    price_transport_element.addEventListener('input', updatePriceProfit);
    price_profit_element.addEventListener('input', updatePriceProfit);

    window.do_price = true;
}

function updatePriceProfit() {
    if (!window.do_price) {
        return;
    }
    let price_customer = parseFloat(price_customer_element.value ?? '0');
    let price_production = parseFloat(price_production_element.value ?? '0');
    let price_assembly = parseFloat(price_assembly_element.value ?? '0');
    let price_tranport = parseFloat(price_transport_element.value ?? '0');

    if (isNaN(price_customer)) {
        price_customer = 0;
    }
    if (isNaN(price_production)) {
        price_production = 0;
    }
    if (isNaN(price_assembly)) {
        price_assembly = 0;
    }
    if (isNaN(price_tranport)) {
        price_tranport = 0;
    }

    price_profit_element.value = (price_customer - (price_production + price_assembly + price_tranport)).toFixed(2);
}


// Set price on page load
const priced_elements = document.querySelectorAll('[data-price]');
priced_elements.forEach(function (element) {
    updateOrderTotal(parseFloat(element.dataset.price));
    updateTransportTotal();
    updatePriceProfit();
});
