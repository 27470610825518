// Isset
function isset(variable) {
    return (typeof variable !== 'undefined' && variable !== null);
}
window.isset = isset;
// End isset

// Conditional showing
const conditional_elements = document.querySelectorAll("[data-conditional-show]");
for (let i = 0; i < conditional_elements.length; i++) {
    let conditional_element = conditional_elements[i];
    let name = conditional_element.dataset.conditionalShow;
    let value = conditional_element.dataset.conditionalValue;

    const input_elements = document.querySelectorAll(`[name=${name}]`);

    let input_element;
    if (input_elements.length === 1) {
        input_element = input_elements[0];
        input_element.addEventListener('change', function () {
            conditional_update(input_element, value, conditional_element);
        });
    } else {
        input_element = document.querySelector(`[name=${name}]:checked`);
        conditional_radio_update(input_elements, value, conditional_element);
    }
    if (isset(input_element)) {
        conditional_update(input_element, value, conditional_element);
    }
}

function conditional_update(input_element, value, display_element) {
    if (input_element.type === 'checkbox') {
        if (input_element.checked && (input_element.value === value || !isset(value))) {
            display_element.style.display = '';
        } else {
            display_element.style.display = 'none';
        }
    } else {
        if (input_element.value === value || (!isset(value) && input_element.value)) {
            display_element.style.display = '';
        } else {
            display_element.style.display = 'none';
        }
    }
}
window.conditional_update = conditional_update;

function conditional_radio_update(input_elements, value, display_element) {
    for (let i = 0; i < input_elements.length; i++) {
        input_elements[i].addEventListener('click', function () {
            conditional_update(this, value, display_element);
        });
    }
}
window.conditional_radio_update = conditional_radio_update;
// End conditional showing


// Template duplicate
function clone_template(template_id, clone_into_id, clone_at_start = false, fill_with = {}) {
    const template = document.getElementById(template_id);
    const clone_into = document.getElementById(clone_into_id);

    const clone = document.createElement('div');

    for (let key in template.dataset) {
        const value = template.dataset[key];
        clone.setAttribute(key, value);
    }

    clone.dataset.cloneOf = template_id;
    clone.innerHTML = template.innerHTML;

    let before = null;
    if (clone_at_start) {
        before = clone_into.childNodes[0];
    }

    /*
     * fill_with
     * key = CSS selector
     * value = element.value or function(element)
     */
    for (let key in fill_with) {
        const value = fill_with[key];
        const elements = clone.querySelectorAll(key);

        for (let element of elements) {
            if (typeof value === 'function') {
                value(element);
            } else {
                element.value = value;
            }
        }
    }

    clone_into.insertBefore(clone, before);


}
window.clone_template = clone_template;

function remove_clone(element, one_must_remain = true) {
    const clone = element.closest('[data-clone-of]');
    const list = clone.parentNode;

    if (one_must_remain) {
        const in_list = list.childNodes;
        if (in_list.length < 2) {
            alert('Het is niet mogelijk het laatste element uit deze lijst te verwijderen');
            return;
        }
    }

    list.removeChild(clone);
}
window.remove_clone = remove_clone;
