<template>
    <div class="container">
        <strong>Orders definitief in planning</strong>
        <div class="list-row" v-if="schedule.orders.length" v-for="order in schedule.orders">
            <schedules-pickup-order :order="order"/>
        </div>
        <div v-else class="alert alert-info">Er zitten nog geen orders definitief in deze planning.</div>
    </div>

    <div class="container">
        <strong>Orders niet in planning met {{ schedule.date.formatted }} als afhaaldag</strong>
        <div class="list-row" v-if="Object.entries(ordersCorrectDay).length" v-for="order in ordersCorrectDay">
            <schedules-pickup-order
                :order="order"
                @order-added="orderAdded"
                @order-removed="orderRemoved"
                :can-confirm-pickup="canConfirmPickup"
            />
        </div>
        <div v-else class="alert alert-info">Er zijn geen orders met deze dag als afhaaldag om in te plannen.</div>
    </div>

    <div class="container">
        <strong>Orders niet in planning met een andere afhaaldag</strong>
        <div class="list-row" v-if="Object.entries(ordersOtherDays).length" v-for="order in ordersOtherDays">
            <schedules-pickup-order
                :order="order"
                @order-added="orderAdded"
                @order-removed="orderRemoved"
                :can-confirm-pickup="canConfirmPickup"
            />
        </div>
        <div v-else class="alert alert-info">Er zijn geen andere orders om in te plannen.</div>
    </div>

    <input type="hidden" :value="orders" name="orders_added"/>
</template>

<script>
export default {
    props: {
        schedule: Object,
        ordersCorrectDay: Object,
        ordersOtherDays: Object,
        canConfirmPickup: Boolean,
    },
    data() {
        return {
            orders: [],
        }
    },
    methods: {
        orderAdded(orderId) {
            this.orders.push(orderId);
        },
        orderRemoved(orderId) {
            this.orders = this.orders.filter((value) => (value !== orderId));
        }
    },
}
</script>
