<template>
    <div class="list-row" v-if="pickups.length" v-for="pickup in pickups">
        <pickups-pickup-order :pickup="pickup"/>
    </div>
    <div v-else class="alert alert-info">Er zijn afhaalorders die nog niet bevestigd zijn.</div>
</template>

<script>
export default {
    props: {
        pickups: Array,
    },
}
</script>
