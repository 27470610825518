window.icons = () => ({
    overdue: {
        icon: {url: "/images/marker-danger.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    correct_day: {
        icon: {url: "/images/marker-success.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    correct_day_last: {
        icon: {
            url: "/images/marker-success-last.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    other_day_has_preference: {
        icon: {
            url: "/images/marker-default.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    other_day: {
        icon: {url: "/images/marker-default.png", scaledSize: new google.maps.Size(12, 18)},
        opacity: 0.7
    },
    bol: {
        icon: {url: "/images/marker-bol.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    redelivery: {
        icon: {url: "/images/marker-redelivery-plain.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    redelivery_before: {
        icon: {url: "/images/marker-redelivery-before.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    redelivery_today: {
        icon: {url: "/images/marker-redelivery-today.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    redelivery_late: {
        icon: {url: "/images/marker-redelivery-late.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    redelivery_other: {
        icon: {url: "/images/marker-redelivery-other.png", scaledSize: new google.maps.Size(16, 24)},
        opacity: 1
    },
    selected: {
        icon: {url: "/images/marker-selected.png", scaledSize: new google.maps.Size(18, 27)},
        opacity: 1
    },
    beddenleeuw: {
        icon: {url: "/images/marker-beddenleeuw.png", scaledSize: new google.maps.Size(18, 27)},
        opacity: 1
    },
    bol_spoed: {
        icon: {url: "/images/marker-bol-spoed.png", scaledSize: new google.maps.Size(20, 28)},
        opacity: 1
    },
    warehouse: {
        icon: {url: "/images/marker-warehouse.png", scaledSize: new google.maps.Size(22, 29)},
        opacity: 1
    },

    overdue_rembours: {
        icon: {
            url: "/images/marker-danger-oranje.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_rembours: {
        icon: {
            url: "/images/marker-success-oranje.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_last_rembours: {
        icon: {
            url: "/images/marker-success-oranje-last.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    other_day_has_preference_rembours: {
        icon: {
            url: "/images/marker-default-oranje.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    other_day_rembours: {
        icon: {
            url: "/images/marker-default-oranje.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    bol_rembours: {
        icon: {url: "/images/marker-bol-oranje.png", scaledSize: new google.maps.Size(12, 18)},
        opacity: 0.7
    },
    selected_rembours: {
        icon: {
            url: "/images/marker-selected.png",
            scaledSize: new google.maps.Size(18, 27)
        }, opacity: 1
    },
    bol_spoed_rembours: {
        icon: {
            url: "/images/marker-bol-spoed-oranje.png",
            scaledSize: new google.maps.Size(20, 28)
        }, opacity: 1
    },

    // With dot
    overdue_external: {
        icon: {
            url: "/images/marker-danger-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_external: {
        icon: {
            url: "/images/marker-success-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_last_external: {
        icon: {
            url: "/images/marker-success-last-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    other_day_external: {
        icon: {
            url: "/images/marker-default-dot.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    other_day_has_preference_external: {
        icon: {
            url: "/images/marker-default-dot.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    bol_external: {
        icon: {url: "/images/marker-bol-dot.png", scaledSize: new google.maps.Size(12, 18)},
        opacity: 0.7
    },
    selected_external: {
        icon: {
            url: "/images/marker-selected-dot.png",
            scaledSize: new google.maps.Size(18, 27)
        }, opacity: 1
    },
    beddenleeuw_external: {
        icon: {
            url: "/images/marker-beddenleeuw-dot.png",
            scaledSize: new google.maps.Size(18, 27)
        }, opacity: 1
    },
    bol_spoed_external: {
        icon: {
            url: "/images/marker-bol-spoed-dot.png",
            scaledSize: new google.maps.Size(20, 28)
        }, opacity: 1
    },
    warehouse_external: {
        icon: {
            url: "/images/marker-warehouse-dot.png",
            scaledSize: new google.maps.Size(22, 29)
        }, opacity: 1
    },

    overdue_rembours_external: {
        icon: {
            url: "/images/marker-danger-oranje-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_rembours_external: {
        icon: {
            url: "/images/marker-success-oranje-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    correct_day_last_rembours_external: {
        icon: {
            url: "/images/marker-success-oranje-last-dot.png",
            scaledSize: new google.maps.Size(16, 24)
        }, opacity: 1
    },
    other_day_has_preference_rembours_external: {
        icon: {
            url: "/images/marker-default-oranje-dot.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    other_day_rembours_external: {
        icon: {
            url: "/images/marker-default-oranje-dot.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    bol_rembours_external: {
        icon: {
            url: "/images/marker-bol-oranje-dot.png",
            scaledSize: new google.maps.Size(12, 18)
        }, opacity: 0.7
    },
    selected_rembours_external: {
        icon: {
            url: "/images/marker-selected-dot.png",
            scaledSize: new google.maps.Size(18, 27)
        }, opacity: 1
    },
    bol_spoed_rembours_external: {
        icon: {
            url: "/images/marker-bol-spoed-oranje-dot.png",
            scaledSize: new google.maps.Size(20, 28)
        }, opacity: 1
    },
});
