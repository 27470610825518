<template>
  <form id="filters" class="filters mb-3">
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label class="text-bold">Organisaties ({{ organisation_group }} / {{ organisations.length }})</label>
          <div style="max-height: 25rem; overflow-y: scroll">
            <div class="form-check" v-for="organisation in organisations">
              <input class="form-check-input" type="checkbox" name="organisation[]" :value="organisation.id"
                  :id="'organisation_' + organisation.id" @change="updateCheckbox" data-group="organisation">
              <label class="form-check-label" :for="'organisation_' + organisation.id">{{ organisation.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group">
          <label class="text-bold">Statussen ({{ status_group }} / {{ Object.keys(statuses).length }})</label>
          <div>
            <div class="form-check" v-for="(label, value) in statuses">
              <input class="form-check-input" type="checkbox" name="status[]" :value="value"
                  :id="'status_' + value" @change="updateCheckbox" data-group="status">
              <label class="form-check-label" :for="'status_' + value">{{ label }}</label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="text-bold">Ordertype ({{ order_type_group }} / {{ Object.keys(orderTypes).length }})</label>
          <div>
            <div class="form-check" v-for="(label, value) in orderTypes">
              <input class="form-check-input" type="checkbox" name="order_type[]" :value="value"
                  :id="'order_type_' + value" @change="updateCheckbox" data-group="order_type">
              <label class="form-check-label" :for="'order_type_' + value">{{ label }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group">
          <label class="text-bold" for="from">Orders vanaf</label>
          <div class="d-flex">
            <input type="datetime-local" name="from" class="form-control w-auto" id="from" @change="getOrders">
            <button type="button" class="btn btn-secondary ml-3" @click="clear('from')">Leegmaken</button>
          </div>
        </div>

        <div class="form-group">
          <label class="text-bold" for="to">Orders tot en met</label>
          <div class="d-flex">
            <input type="datetime-local" name="to" class="form-control w-auto" id="to" @change="getOrders">
            <button type="button" class="btn btn-secondary ml-3" @click="clear('to')">Leegmaken</button>
          </div>
        </div>

        <div class="form-group">
          <label class="text-bold">Extra opties ({{ extra_group }} / {{ Object.keys(extras).length }})</label>
          <div class="form-check" v-for="(label, value) in extras">
            <input type="checkbox" class="form-check-input" :name="'extra[' + value + ']'" value="1" :id="'extra_' + value "
                @change="updateCheckbox" data-group="extra">
            <label :for="'extra_' + value " class="form-check-label">{{ label }}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div id="results">
    <h3>Aantal orders: <span class="js-spinner"></span><span class="js-hide-while-loading">{{ results }}</span></h3>
  </div>
</template>

<script>
export default {
  props: [
    'organisations',
    'statuses',
    'orderTypes',
    'extras',
  ],
  data() {
    return {
      orders: {},
      results: '',
      organisation_group: 0,
      status_group: 0,
      order_type_group: 0,
      extra_group: 0,
    }
  },
  methods: {
    getOrders() {
      const context = this;
      this.enableLoading();
      axios({
        method: 'get',
        url: route('api.order-filter'),
        params: new URLSearchParams(new FormData(document.getElementById('filters'))),
      })
          .then(function (response) {
            context.results = response.data.count;
            context.disableLoading();
          });
    },
    updateCheckbox(event) {
      const element = event.target;
      const group_prefix = element.dataset.group ?? 'default';
      const group = group_prefix + "_group";
      const checked = document.querySelectorAll(`#app [data-group='${group_prefix}']:checked`);
      this[group] = checked.length;
      this.getOrders();
    },
    enableLoading() {
      const spinners = document.querySelectorAll('#app .js-spinner');
      spinners.forEach(function (spinner) {
        spinner.innerHTML = '<i class="far fa-spinner fa-spin"></i>'
      });
      const hides = document.querySelectorAll('#app .js-hide-while-loading');
      hides.forEach(function (hide) {
        hide.setAttribute('hidden', 'hidden');
      });
    },
    disableLoading() {
      const spinners = document.querySelectorAll('#app .js-spinner');
      spinners.forEach(function (spinner) {
        spinner.innerHTML = '';
      });
      const hides = document.querySelectorAll('#app .js-hide-while-loading');
      hides.forEach(function (hide) {
        hide.removeAttribute('hidden');
      });
    },
    clear(id) {
      document.getElementById(id).value = '';
      this.getOrders();
    },
  },
  mounted() {
    this.getOrders();
  }
};
</script>
