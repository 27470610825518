"use strict";


require('./bootstrap');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/datepicker');

import Sortable from "sortablejs";
import Choices from "choices.js";

import {createApp} from 'vue';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue'; // Used to make the Laravel routes available in JS

// Make choices available to all scripts
window.Choices = Choices;

// Vue Components
import OrderIndex from "./components/Orders/Index.vue";
import OrderFilter from "./components/Orders/Filter.vue";
import OrderStatistics from "./components/Orders/Statistics.vue";
import PickupIndex from "./components/Pickups/Index.vue";
import PickupPickupOrder from "./components/Pickups/PickupOrder.vue";
import SchedulesIndex from "./components/Schedules/Index.vue";
import SchedulesMove from "./components/Schedules/Move.vue";
import SchedulesPickup from './components/Schedules/Pickup';
import SchedulesPickupOrder from './components/Schedules/PickupOrder';


if (pageUsesVue()) {
    createApp({})
        .use(ZiggyVue)
        .component('orders-index', OrderIndex)
        .component('orders-filter', OrderFilter)
        .component('orders-statistics', OrderStatistics)
        .component('pickups-index', PickupIndex)
        .component('pickups-pickup-order', PickupPickupOrder)
        .component('schedules-index', SchedulesIndex)
        .component('schedules-move', SchedulesMove)
        .component('schedules-pickup', SchedulesPickup)
        .component('schedules-pickup-order', SchedulesPickupOrder)
        .mount('#app');
}

function pageUsesVue() {
    return 'usesVue' in document.body.dataset;
}

const orderableElements = document.querySelectorAll('[data-sortable]');
const defaultOrderableOptions = {
    forceFallback: true,
    fallbackOnBody: true,
    direction: 'vertical',
    swapTreshold: 0.2,
};

[...orderableElements].forEach(function (orderableElement) {
    const options = orderableElement.dataset.sortableOptions
        ? {...defaultOrderableOptions, ...JSON.parse(orderableElement.dataset.sortableOptions)}
        : defaultOrderableOptions;
    new Sortable(orderableElement, options);
});

// Prevent double submits
[...document.querySelectorAll('button[type=submit]:not([onclick]):not([data-custom-submit])')]
    .filter(button => button.form && button.form.onsubmit === null)
    .forEach(button => {
        button.onclick = function () {
            if (!button.form.checkValidity()) {
                // Do nothing if the form is invalid, the browser will
                // prevent submission and point out the validation errors
                return;
            }

            if (button.hasAttribute('disabled')) {
                // Allow conscious double submission after confirmation
                // if the browser allows submitting from a disabled button
                return confirm('Weet je zeker dat je dit nog een keer wilt doen?');
            }

            // Disable the button
            button.setAttribute('disabled', 'disabled');
            // Custom submit the form, because the button is disabled now
            button.form.submit();
            return false;
        };
    });

if ($("#add-option").length) {
    $("#add-option").click(function (e) {
        e.preventDefault();
        $("#options").append($(".option").first().clone());
        $(".option").last().find('input').val("");
    });
}

function removeAttributeOption(option) {
    if ($('.js-option').length > 1) {
        $(option).closest('.js-option').remove();
    }
}

window.removeAttributeOption = removeAttributeOption;

if ($("#add-attribute").length) {
    $("#add-attribute").click(function (e) {
        e.preventDefault();
        $("#attributes").append($(".option").first().clone());
        $(".option").last().find('select').val($(".option").last().find('select option:first').val());

        $(".remove-attribute").click(function () {
            $(this).parent().remove();
        });
    });
}

if ($(".remove-attribute").length) {
    $(".remove-attribute").click(function () {
        $(this).parent().remove();
    });
}

function removeProductAttribute(attribute) {
    if ($('.js-attribute').length > 1) {
        $(attribute).closest('.js-attribute').remove();
    }
}

window.removeProductAttribute = removeProductAttribute;

function addProductNote(type) {
    $(`#product-note-${type}`).append(
        $(`.js-note-${type}`).first().clone()
    );

    $(`.js-note-${type}`).last().find('input').val('');
}

window.addProductNote = addProductNote;

function removeProductNote(note, type) {
    const element = `.js-note-${type}`;
    if ($(element).length > 1) {
        $(note).closest(element).remove();
    } else {
        $(element).find('input').val('');
    }
}

window.removeProductNote = removeProductNote;

// Save / save and return buttons
$('.stay-setter').click(function (e){
    e.preventDefault();
    const input = document.getElementById('stay-input');
    input.value = e.target.value;
    // disable submit buttons to prevent double submits
    $('.stay-setter').attr('disabled','disabled');
    input.form.submit();
})

require('./utils');
require('./order');
require('./map-icons');

const choiceElements = document.querySelectorAll('.js-choice');
choiceElements.forEach(choiceElement => {
    new Choices(choiceElement, {
        removeItems: true,
        removeItemButton: true,
        noChoicesText: 'Geen andere opties',
        noResultsText: 'Geen resultaten',
        itemSelectText: '',
    });
});
