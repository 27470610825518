<template>
    <form method="post" :action="route('schedules.move', schedule.id)">
        <input type="hidden" name="_token" :value="csrfToken"/>
        <div class="row">
            <div class="col-lg-6">
                Aanpassen van {{ schedule.date.formatted }} naar
                <input type="date" name="new_date" :min="today" class="form-inline d-inline" v-model="newDate"/>
                <span :class="'ml-2 p-2 badge badge-' + validityColor">
                    <i :class="'far fa-' + validityIcon"></i>
                </span>
            </div>
            <div class="col-lg-6 d-flex gap-2 justify-content-end flex-wrap">
                <strong v-if="showSuggestions">Datum suggesties:</strong>
                <button v-for="(preferences, date) in dateSuggestions" @click="setDate(date)" class="btn btn-sm btn-primary">
                    {{ date }}, {{ preferences}} orders
                </button>
            </div>
        </div>
        <div class="mt-3">
            <div class="list-row text-bold py-1">
                <div class="row">
                    <div class="col-lg-2">Ordernummer</div>
                    <div class="col-lg-2">Organisatie</div>
                    <div class="col-lg-8">Voorkeursdagen (<span class="strikethrough">al voorbij</span>)</div>
                </div>
            </div>
            <div v-for="order in schedule.orders" class="list-row">
                <div class="row">
                    <div class="col-lg-2">
                        <a :href="route('orders.edit', order.id)" target="_blank" class="text-decoration-none text-dark">
                            <strong>#{{ order.order_nr }}</strong>
                        </a>
                    </div>
                    <div class="col-lg-2">
                        {{ order.organisation.name }}
                    </div>
                    <div class="col-lg-8 d-flex flex-wrap gap-2">
                        <span
                            v-for="preferenceDay in order.preference_days"
                            :class="{
                                'text-bold': preferenceDay.datum === this.newDate,
                                'strikethrough': preferenceDay.datum < this.today,
                                'd-none': preferenceDay.datum === schedule.date.date,
                            }">
                            {{ preferenceDay.datum }}
                        </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="mt-3">
            <div class="form-check mb-3">
                <input type="checkbox" id="notify" name="notify" v-model="notify" class="form-check-input" value="1"/>
                <label for="notify" class="form-check-label">Verstuur een mail dat de planning verplaatst is</label>
            </div>
            <div class="form-group" :class="{ 'd-none': !this.notify }">
                <label for="message">Bericht aan de klant</label>
                <textarea id="message" name="message" class="form-control" placeholder="Laat leeg om geen bericht/reden op te geven..."></textarea>
                <small class="text-muted">De tekst van dit bericht wordt binnen de mail geplaatst, en hoeft dus niet te beginnen met een aanhef of af te sluiten met een groet.</small>
            </div>
            <button type="submit" :disabled="validity !== 'valid'" class="btn btn-primary">
                Datum wijzigen
            </button>
        </div>
    </form>
</template>

<script>
export default {
    name: 'schedules-move',
    props: [
        "schedule",
        "today",
        "csrfToken",
    ],
    data() {
        return {
            newDate: null,
            dateSuggestions: {},
            notify: !!this.schedule.status,
        };
    },
    computed: {
        validity() {
            return (this.newDate === null) ? 'indeterminate' :
                (this.newDate === this.schedule.date.date || this.newDate < this.today) ? 'invalid' :
                    'valid';
        },
        validityIcon() {
            const match = {
                indeterminate: 'question-circle',
                invalid: 'times-circle',
                valid: 'check-circle',
            };
            return match[this.validity];
        },
        validityColor() {
            const match = {
                indeterminate: 'warning',
                invalid: 'danger',
                valid: 'success',
            }
            return match[this.validity];
        },
        showSuggestions() {
            return Object.keys(this.dateSuggestions).length > 1;
        },
    },

    methods: {
        setDate(date) {
            this.newDate = date;
        },
        getSuggestedDates() {
            const preferences = {};
            this.schedule.orders.forEach((order) => {
                order.preference_days.forEach((preferenceDay) => {
                    preferences[preferenceDay.datum] = (preferences[preferenceDay.datum] ?? 0) + 1;
                })
            });

            const today = this.today;

            this.dateSuggestions = Object.fromEntries(
                Object.entries(preferences)
                    // Don't suggest the date of the current planning, dates in the past, or dates with just one order
                    .filter((tuple) => tuple[0] !== this.schedule.date.date && tuple[0] >= today && tuple[1] > 1)
                    // Sort by number of orders with this date, descending
                    .sort((a, b) => b[1] - a[1]));
        },
        updatePreferenceMarkers() {

        },
    },
    mounted() {
        this.getSuggestedDates();
    }
};
</script>
