<template>
    <div class="row align-items-center">
        <div class="col-3">
            <strong v-if="order.pickup.pickup_date.isToday">
                Vandaag
            </strong>
            <strong v-else-if="order.pickup.pickup_date.isTomorrow">
                Morgen
            </strong>
            <strong v-else>
                {{ order.pickup.pickup_date.formatted[0].toUpperCase() + order.pickup.pickup_date.formatted.slice(1) }}
            </strong>
        </div>
        <div class="col-2">
            #{{ order.order_nr }}
        </div>
        <div class="col-2">
            {{ order.name }}
        </div>
        <div class="col-5 d-flex justify-content-end flex-wrap gap-2">
            <a :href="route('orders.edit', order.id)" target="_blank" class="btn btn-primary">
                <i class="far fa-eye"></i> Order bekijken
            </a>
            <button
                v-if="order.schedule_id"
                type="button"
                class="btn btn-secondary"
                disabled
            >
                <!-- Order permanent in planning -->
                <i class="far fa-check"></i> Toegevoegd
            </button>
            <button
                v-else-if="this.$parent.orders.includes(order.id)"
                type="button"
                @click="this.$emit('order-removed', order.id)"
                class="btn btn-warning"
            >
                <!-- Order in planning, not permanent -->
                <i class="far fa-check"></i> Toegevoegd
            </button>
            <button
                v-else-if="canConfirmPickup"
                type="button"
                @click="this.$emit('order-added', order.id)"
                class="btn btn-warning"
            >
                <!-- Order not in planning -->
                Toevoegen <i class="far fa-arrow-right"></i>
            </button>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: Object,
        canConfirmPickup: Boolean
    },
    emits: [
        'order-added',
        'order-removed',
    ],
}
</script>
